import React from "react";
import "../Css/Unauthorized.css";
import Unauth from "../Assets/unauthorized.svg";

function Unauthorized(props) {
	return (
		<div className="Unauthorized">
			<div>
				<img src={Unauth} alt="Unauthorized" />
				<h1>Unauthorized</h1>
				<h2>Please log in with an authorized account to access this page.</h2>
			</div>
		</div>
	);
}

export default Unauthorized;