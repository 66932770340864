module.exports = {
	ApiEndPointRoot: "https://tools.alistairfink.com/waleetcode/",
	ApiEndPoints: {
		Answer: "answer",
		Description: "description",
		Question: "question",
		DescriptionType: "description_type",
		Difficulty: "difficulty",
		TestRun: "answer/test_run",
		DefaultTestCase: "test_case/{question_uuid}/default",
		TestCase: "test_case"
	},
	ApiKey: "",
}