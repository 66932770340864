import Config from "../Config/Config.js"

class RestClient {
	GetQuestions(callback) {
		fetch(Config.ApiEndPointRoot + Config.ApiEndPoints.Question)
			.then((response) => response.json())
			.then((responseJson) => {
				callback(responseJson);
			});
	}
	
	GetDescriptions(callback, questionUuid, sorted) {
		fetch(Config.ApiEndPointRoot + Config.ApiEndPoints.Description + "/" + questionUuid + "?sort=" + sorted)
			.then((response) => response.json())
			.then((responseJson) => {
				callback(responseJson);
			}); 
	}

	GetQuestion(callback, questionUuid) {
		fetch(Config.ApiEndPointRoot + Config.ApiEndPoints.Question + "/" + questionUuid)
			.then((response) => response.json())
			.then((responseJson) => {
				callback(responseJson);
			});
	}

	GetQuestionAdmin(callback, questionUuid, token) {
		fetch(Config.ApiEndPointRoot + Config.ApiEndPoints.Question + "/" + questionUuid + "/admin", {
			headers: {
				"Authorization": `Bearer ${token}`
			}
		})
		.then((response) => response.json())
		.then((responseJson) => {
			callback(responseJson);
		});
	}

	SubmitAnswer(callback, tooManyCallback, questionUuid, text, token) {
		fetch(Config.ApiEndPointRoot + Config.ApiEndPoints.Answer, {
			method: "POST",
			headers: {
				"Authorization": `Bearer ${token}`
			},
			body: JSON.stringify({
				QuestionUuid: questionUuid,
				Code: text
			})
		})
		.then((response) => {
			let res = response.json();
			if(response.status === 429) {
				tooManyCallback(response.statusText);
			} else {
				res.then((responseJson) => {
					callback(responseJson);
				});
			}
		});
	}

	SubmitTestRun(callback, tooManyCallback, questionUuid, code, token, testCases) {
		let testCaseLines = testCases.split('\n');
		let strBuilder = "[";
		for(let testCase of testCaseLines) {
			let args = testCase.split(' ');
			let innerStrBuilder = "["
			for(let arg of args) {
				innerStrBuilder += arg + ","
			}
			innerStrBuilder = innerStrBuilder.substring(0, innerStrBuilder.length - 1)
			strBuilder += innerStrBuilder + "],"
		}
		strBuilder = strBuilder.substring(0, strBuilder.length - 1) + "]"

		fetch(Config.ApiEndPointRoot + Config.ApiEndPoints.TestRun, {
			method: "POST",
			headers: {
				"Authorization": `Bearer ${token}`
			},
			body: JSON.stringify({
				QuestionUuid: questionUuid,
				Code: code,
				TestCases: strBuilder
			})
		})
		.then((response) => {
			let res = response.json();
			if(response.status === 429) {
				tooManyCallback(response.statusText);
			} else {
				res.then((responseJson) => {
					callback(responseJson);
				});
			}
		});
	}

	GetDifficulties(callback) {
		fetch(Config.ApiEndPointRoot + Config.ApiEndPoints.Difficulty)
			.then((response) => response.json())
			.then((responseJson) => {
				callback(responseJson);
			});

	}

	GetDescriptionTypes(callback) {
		fetch(Config.ApiEndPointRoot + Config.ApiEndPoints.DescriptionType)
			.then((response) => response.json())
			.then((responseJson) => {
				callback(responseJson);
			});

	}

	async CreateQuestion(
		callback, 
		title, 
		summary, 
		difficulty, 
		descriptions, 
		token, 
		difficulties, 
		descriptionTypes, 
		answerFrameEntryPoint, 
		answerFrame, 
		solution, 
		testCases
	) {
		let questionResponse = await fetch(Config.ApiEndPointRoot + Config.ApiEndPoints.Question, {
		  method: "POST",
		  headers: {
		    "Authorization": `Bearer ${token}`,
		    "Content-Type": "application/json"
		  },
		  body: JSON.stringify({
		  	"Title": title,
		  	"Summary": summary,
		  	"Difficulty": {
		  		"Id": parseInt(difficulty),
		  		"Title": difficulties[difficulty]
		  	},
		  	"AnswerFrame": answerFrame,
		  	"SolutionCode": solution,
		  	"AnswerFrameEntrypoint": answerFrameEntryPoint
		  })
		});

		callback();
		let questionJson = await questionResponse.json();
		
		for(let i = 0; i < descriptions.length; i++) {
			fetch(Config.ApiEndPointRoot + Config.ApiEndPoints.Description, {
			  method: "POST",
			  headers: {
			    "Authorization": `Bearer ${token}`,
			    "Content-Type": "application/json"
			  },
			  body: JSON.stringify({
			  	"QuestionUuid": questionJson.Uuid,
			  	"DescriptionType": {
			  		"Id": parseInt(descriptions[i].Type),
			  		"Type": descriptionTypes[descriptions[i].Type]
			  	},
			 		"Content": descriptions[i].Content,
			 		"SortOrder": parseInt(descriptions[i].SortOrder)
			  })
			});
		}

		for(let i = 0; i < testCases.length; i++) {
			fetch(Config.ApiEndPointRoot + Config.ApiEndPoints.TestCase, {
				method: "POST",
				headers: {
					"Authorization": `Bearer ${token}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					"QuestionUuid": questionJson.Uuid,
					"Input": testCases[i].Input,
					"ExpectedOutput": testCases[i].ExpectedOutput
				})
			});
		}
	}

	EditQuestion(
		callback, 
		questionUuid, 
		title, 
		summary, 
		difficulty, 
		descriptions, 
		originalDescriptions, 
		token, 
		difficulties, 
		descriptionTypes, 
		answerFrameEntryPoint, 
		answerFrame, 
		solution,
		testCases,
		totalTestCases
	) {
		fetch(Config.ApiEndPointRoot + Config.ApiEndPoints.Question, {
		  method: "PUT",
		  headers: {
		    "Authorization": `Bearer ${token}`,
		    "Content-Type": "application/json"
		  },
		  body: JSON.stringify({
		  	"Uuid": questionUuid,
		  	"Title": title,
		  	"Summary": summary,
		  	"Difficulty": {
		  		"Id": parseInt(difficulty),
		  		"Title": difficulties[difficulty]
		  	},
		  	"AnswerFrame": answerFrame,
		  	"SolutionCode": solution,
		  	"AnswerFrameEntrypoint": answerFrameEntryPoint
		  })
		})
		.then(() => callback());

		// Descriptions
		let keepMap = {};
		for(let i = 0; i < descriptions.length; i++) {
			if(!descriptions[i].Uuid) {
				fetch(Config.ApiEndPointRoot + Config.ApiEndPoints.Description, {
				  method: "POST",
				  headers: {
				    "Authorization": `Bearer ${token}`,
				    "Content-Type": "application/json"
				  },
				  body: JSON.stringify({
				  	"QuestionUuid": questionUuid,
				  	"DescriptionType": {
				  		"Id": parseInt(descriptions[i].Type),
				  		"Type": descriptionTypes[descriptions[i].Type]
				  	},
				 		"Content": descriptions[i].Content,
				 		"SortOrder": parseInt(descriptions[i].SortOrder)
				  })
				});
			} else {
				keepMap[descriptions[i].Uuid] = true;
				fetch(Config.ApiEndPointRoot + Config.ApiEndPoints.Description, {
				  method: "PUT",
				  headers: {
				    "Authorization": `Bearer ${token}`,
				    "Content-Type": "application/json"
				  },
				  body: JSON.stringify({
				  	"Uuid": descriptions[i].Uuid,
				  	"QuestionUuid": questionUuid,
				  	"DescriptionType": {
				  		"Id": parseInt(descriptions[i].Type),
				  		"Type": descriptionTypes[descriptions[i].Type]
				  	},
				 		"Content": descriptions[i].Content,
				 		"SortOrder": parseInt(descriptions[i].SortOrder)
				  })
				});
			}
		}

		for(let i = 0; i < originalDescriptions.length; i++) {
			if(!keepMap[originalDescriptions[i].Uuid]) {
				fetch(Config.ApiEndPointRoot + Config.ApiEndPoints.Description + "/" + originalDescriptions[i].Uuid, {
				  method: "DELETE",
				  headers: {
				    "Authorization": `Bearer ${token}`
				  }
				});
			}
		}

		// Test Cases
		const updateTestCaseFunc = (async() => {
			let updateTestCases = [];
			for(let i = 0; i < totalTestCases; i++) {
				updateTestCases.push(null);
			}
			let newTestCases = [];
			for(let i = 0; i < testCases.length; i++) {
				if(!testCases[i].TestCaseNumber) {
					newTestCases.push(testCases[i]);
				} else {
					updateTestCases[testCases[i].TestCaseNumber - 1] = testCases[i];
				}
			}

			for(let i = updateTestCases.length - 1; i >= 0; i--) {
				if(updateTestCases[i]) {
					await fetch(Config.ApiEndPointRoot + Config.ApiEndPoints.TestCase, {
					  method: "PUT",
					  headers: {
					    "Authorization": `Bearer ${token}`,
					    "Content-Type": "application/json"
					  },
						body: JSON.stringify({
							"TestCaseNumber": updateTestCases[i].TestCaseNumber,
							"QuestionUuid": questionUuid,
							"Input": updateTestCases[i].Input,
							"ExpectedOutput": updateTestCases[i].ExpectedOutput
						})
					});
				} else {
					await fetch(Config.ApiEndPointRoot + Config.ApiEndPoints.TestCase + "/" + (i+1) + "/question/" + questionUuid, {
					  method: "DELETE",
					  headers: {
					    "Authorization": `Bearer ${token}`
					  }
					});
				}
			}

			for(let i = 0; i < newTestCases.length; i++) {
				await fetch(Config.ApiEndPointRoot + Config.ApiEndPoints.TestCase, {
					method: "POST",
					headers: {
						"Authorization": `Bearer ${token}`,
						"Content-Type": "application/json"
					},
					body: JSON.stringify({
						"QuestionUuid": questionUuid,
						"Input": newTestCases[i].Input,
						"ExpectedOutput": newTestCases[i].ExpectedOutput
					})
				});
			}
		});
		updateTestCaseFunc().then(() => callback());
	}

	DeleteQuestion(callback, questionUuid, token) {
		fetch(Config.ApiEndPointRoot + Config.ApiEndPoints.Question + "/" + questionUuid, {
		  method: "DELETE",
		  headers: {
		    "Authorization": `Bearer ${token}`
		  }
		})
		.then(() => callback());		
	}

	GetDefaultTestCase(callback, questionUuid) {
		fetch(Config.ApiEndPointRoot + Config.ApiEndPoints.DefaultTestCase.replace("{question_uuid}", questionUuid))
			.then((response) => response.json())
			.then((responseJson) => {
				callback(responseJson);
			});
	}

	GetTestCases(callback, questionUuid, token) {
		fetch(Config.ApiEndPointRoot + Config.ApiEndPoints.TestCase + "/" + questionUuid + "?sort=true",{
		  method: "GET",
		  headers: {
		    "Authorization": `Bearer ${token}`
		  }
		})
		.then((response) => response.json())
		.then((responseJson) => {
			callback(responseJson);
		});
	}
}

export default RestClient;