import React, { useState, useEffect } from "react"
import logo from "./logo.svg";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home.js";
import Questions from "./Pages/Questions.js";
import Problem from "./Pages/Problem.js";
import Profile from "./Pages/Profile.js";
import Admin from "./Pages/Admin.js";
import { useAuth0 } from "./Utilities/Auth0.js";
import DecodeJWT from "./Utilities/DecodeJWT.js";

function App(props) {
  return (
    <Router>
      <div className="Application">
        <Header />
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}/>
        <Route exact path={`${process.env.PUBLIC_URL}/questions`} component={Questions} />
        <Route exact path={`${process.env.PUBLIC_URL}/questions/:QuestionUuid`} component={Problem} />
        <Route exact path={`${process.env.PUBLIC_URL}/profile`} component={Profile} />
        <Route exact path={`${process.env.PUBLIC_URL}/admin`} component={Admin} />
      </div>
    </Router>
  );
}

function Header(props) {
  const [adminCheck, setAdminCheck] = useState(true);
  const [isAdmin, setIsAdmin] = useState(null);
  const { isAuthenticated, loginWithRedirect, logout, getTokenSilently } = useAuth0(); 

  useEffect(() => {
    checkAdmin();
  });

  const checkAdmin = (async () => {
    if(adminCheck && isAuthenticated) {
      setAdminCheck(false);
      let token = await getTokenSilently();
      let decoder = new DecodeJWT();
      if(decoder.IsAdmin(token)) {
        setIsAdmin(true);
      }
    }
  });

  return (
    <ul className="App-Header">
      <li className="Header-Link" >
        <NavLink className="Header-Link" activeClassName="Header-Link-Active" exact to={`${process.env.PUBLIC_URL}/`}>Home</NavLink>
      </li>
      <li className="Header-Link" >
        <NavLink className="Header-Link" activeClassName="Header-Link-Active" exact to={`${process.env.PUBLIC_URL}/questions`}>Questions</NavLink>
      </li>
      {!isAuthenticated ? ( 
        <li className="Header-Link" >
          <NavLink className="Header-Link" activeClassName="Header-Link-Active" onClick={() => loginWithRedirect({})}>Login</NavLink>
        </li>
      ) : (
        <div>
        <li className="Header-Link" >
          <NavLink className="Header-Link" activeClassName="Header-Link-Active" exact to={`${process.env.PUBLIC_URL}/profile`}>Profile</NavLink>
        </li>
        {isAdmin &&
          <li className="Header-Link" >
            <NavLink className="Header-Link" activeClassName="Header-Link-Active" exact to={`${process.env.PUBLIC_URL}/admin`}>Admin</NavLink>
          </li>
        }
        <li className="Header-Link" >
          <NavLink className="Header-Link" activeClassName="Header-Link-Active" onClick={() => logout()}>Logout</NavLink>
        </li>
        <li className="Header-Link" >
          <NavLink className="Header-Link" activeClassName="Header-Link-Active" onClick={async() => {
            var el = document.createElement('textarea');
            el.value = await getTokenSilently();
            el.setAttribute('readonly', '');
            el.style = {position: 'absolute', left: '-9999px'};
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
          }}>Copy Token to Clipboard</NavLink>
        </li>
        </div>
      )}
    </ul>
  );
}

export default App;