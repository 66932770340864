import React from "react";
import { useAuth0 } from "../Utilities/Auth0.js";

function Profile(props) {
  const { isAuthenticated } = useAuth0(); 

  if(!isAuthenticated) {
  	return (
  		<h1>Unauthorized</h1>
  	);
  }
	return (
		<h1>Profile</h1>
	);
}

export default Profile;