import React, { useState, useEffect } from "react"
import { useAuth0 } from "../Utilities/Auth0.js";
import DecodeJWT from "../Utilities/DecodeJWT.js";
import Unauthorized from "./Unauthorized.js";
import RestClient from "../Utilities/RestClient.js"
import "../Css/Admin.css";
import AceEditor from 'react-ace';
import "brace/mode/python";
import "brace/mode/plain_text";
import "brace/theme/dreamweaver"

function Admin(props) {
  const [adminCheck, setAdminCheck] = useState(true);
  const [isAdmin, setIsAdmin] = useState(null);
  const [difficulties, setDifficulties] = useState(null);
  const [descriptionTypes, setDescriptionTypes] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [editQuestionIndex, setEditQuestionIndex] = useState(0);

  const { isAuthenticated, getTokenSilently } = useAuth0(); 

  useEffect(() => {
		var restClient = new RestClient();

		restClient.GetDifficulties((diffs) => {
			let diffMap = {};
			for(let i = 0; i < diffs.length; i++) {
				diffMap[diffs[i].Id] = diffs[i].Title;
			}
	  	setDifficulties(diffMap);
	  });

		restClient.GetDescriptionTypes((types) => {
			let typeMap = {};
			for(let i = 0; i < types.length; i++) {
				typeMap[types[i].Id] = types[i].Type;
			}
	  	setDescriptionTypes(typeMap);
	  });

		restClient.GetQuestions((questions) => {
			setQuestions(questions);
		});
  }, [setDescriptionTypes, setDifficulties, setQuestions]);

  useEffect(() => {
    checkAdmin();
  });

  const checkAdmin = (async () => {
    if(adminCheck && isAuthenticated) {
      setAdminCheck(false);
      let token = await getTokenSilently();
      let decoder = new DecodeJWT();
      if(decoder.IsAdmin(token)) {
        setIsAdmin(true);
      }
    }
  });

  const getQuestions = (() => {
		var restClient = new RestClient();
		setEditQuestionIndex(0);
		restClient.GetQuestions(getQuestionsCallback);
  });

  const getQuestionsCallback = ((json) => {
  	setQuestions(json);
  });

  if(!isAdmin) {
  	return (
  		<Unauthorized />
		);
  }

	return (
		<div className="admin-outter">
			<h1>Admin</h1>
			<button onClick={async() => {
          var el = document.createElement('textarea');
          el.value = await getTokenSilently();
          el.setAttribute('readonly', '');
          el.style = {position: 'absolute', left: '-9999px'};
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);
        }}
      >Copy Token to Clickboard</button>
			<h3>New Question</h3>
			{difficulties !== null && descriptionTypes !== null &&
      	<NewQuestion Difficulties={difficulties} DescriptionTypes={descriptionTypes} GetQuestions={() => getQuestions()}/>
      }

			<h3>Edit Question</h3>
			<select value={editQuestionIndex} onChange={(event) => setEditQuestionIndex(event.target.value)} >
				{questions !== null && questions.map((question, i) => (
					<option key={question.Uuid} value={i}>{question.Title}</option>
				))}
			</select>
			{difficulties !== null && descriptionTypes !== null && questions !== null &&
				<EditQuestion Question={questions[editQuestionIndex]} GetQuestions={() => getQuestions()} Difficulties={difficulties} DescriptionTypes={descriptionTypes}/>
			}
		</div>
	);
}

function NewQuestion(props) {
	const [title, setTitle] = useState("");
	const [summary, setSummary] = useState("");
	const [difficulty, setDifficulty] = useState(Object.keys(props.Difficulties)[0]);
	const [descriptions, setDescriptions] = useState([{
		Content: "",
		Type: Object.keys(props.DescriptionTypes)[0],
		SortOrder: 1,
	}]);
	const [answerFrameEntryPoint, setAnswerFrameEntryPoint] = useState("");
	const [answerFrame, setAnswerFrame] = useState("");
	const [solution, setSolution] = useState("");
	const [testCases, setTestCases] = useState([{
		Input: "",
		ExpectedOutput: "",
	}]);

	const { getTokenSilently } = useAuth0(); 

	const submitQuestion = (async () => {
		let token = await getTokenSilently();
		let restClient = new RestClient();
		restClient.CreateQuestion(
			createQuestionCallback, 
			title, 
			summary, 
			difficulty, 
			descriptions, 
			token, 
			props.Difficulties, 
			props.DescriptionTypes, 
			answerFrameEntryPoint, 
			answerFrame, 
			solution,
			testCases
		);
	});

	const createQuestionCallback = (() => {
		setTitle("");
		setSummary("");
		setDifficulty(Object.keys(props.Difficulties)[0]);
		setDescriptions([{
			Content: "",
			Type: Object.keys(props.DescriptionTypes)[0],
			SortOrder: 1,
		}]);
		props.GetQuestions();
	});

	const removeDesc = ((index) => {
		let newDescs = [...descriptions];
		newDescs.splice(index, 1);
		setDescriptions(newDescs);
	});

	const addDesc = (() => {
		let newDescs = [...descriptions];
		newDescs.push({
			Content: "",
			Type: Object.keys(props.DescriptionTypes)[0],
			SortOrder: 1,
		});
		setDescriptions(newDescs);
	});

	const addTestCase = (() => {
		let newTestCases = [...testCases];
		newTestCases.push({
			Input: "",
			ExpectedOutput: "",
		});
		setTestCases(newTestCases);

	});

	const removeTestCase = ((index) => {
		let newTestCases = [...testCases];
		newTestCases.splice(index, 1);
		setTestCases(newTestCases);
	});

	return (
		<div>
				<button onClick={() => submitQuestion()} >Submit New Question</button><br/><br/>
				<label>
					{"Title: "}
					<input type="text" value={title} onChange={(event) => setTitle(event.target.value)} />
				</label><br/>
				<label>
					{"Summary: "}
					<input type="text" value={summary} onChange={(event) => setSummary(event.target.value)} />
				</label><br/>
				<label>
					{"Answer Frame: "}
					<AceEditor
				    mode="python"
				    theme="dreamweaver"
				    name="New-Answer-Frame"
				    height="100px"
				    width="100%"
				    fontSize="0.9rem"
				    value={answerFrame}
				    onChange={(text) => setAnswerFrame(text)}
				    showPrintMargin={false}
					  setOptions={{
						  tabSize: 4,
					  }}
				  />
				</label><br/>
				<label>
					{"Answer Frame Entrypoint: "}
					<input type="text" value={answerFrameEntryPoint} onChange={(event) => setAnswerFrameEntryPoint(event.target.value)} />
				</label><br/>
				<label>
					{"Solution: "}
					<AceEditor
				    mode="python"
				    theme="dreamweaver"
				    name="New-Answer-Frame"
				    height="100px"
				    width="100%"
				    fontSize="0.9rem"
				    value={solution}
				    onChange={(text) => setSolution(text)}
				    showPrintMargin={false}
					  setOptions={{
						  tabSize: 4,
					  }}
				  />
				</label><br/>
				<label>
					{"Difficulty: "}
					<select value={difficulty} onChange={(event) => setDifficulty(event.target.value)} >
						{Object.keys(props.Difficulties).map((diff) => (
							<option key={diff} value={diff}>{props.Difficulties[diff]}</option>
						))}
					</select>
				</label><br/>
				<label>
					<h4>{"Descriptions: "}
					<button onClick={() => addDesc()}>Add</button></h4>
				</label><br/>
				{descriptions.map((desc, i) => (
					<div key={i}>
						<label>
							{(i + 1) + ": "}
							<button onClick={() => removeDesc(i)} >Remove</button>
						</label><br/>
						<label>
							{"Content: "}
							<input type="text" value={descriptions[i].Content} onChange={(event) => {
								let newDescs = [...descriptions];
								newDescs[i].Content = event.target.value;
								setDescriptions(newDescs);
							}} />
						</label><br/>
						<label>
							{"Type: "}
							<select value={descriptions[i].Type} onChange={(event) => {
								let newDescs = [...descriptions];
								newDescs[i].Type = event.target.value;
								setDescriptions(newDescs);
							}}>
								{Object.keys(props.DescriptionTypes).map((descT) => (
									<option key={descT} value={descT}>{props.DescriptionTypes[descT]}</option>
								))}
							</select>
						</label><br/>
						<label>
							{"Sort Order: "}
							<input type="number" value={descriptions[i].SortOrder} onChange={(event) => {
								let newDescs = [...descriptions];
								newDescs[i].SortOrder = event.target.value;
								setDescriptions(newDescs);
							}} />
						</label><br/>
					</div>
				))}
				<label>
					<h4>{"Test Cases: "}
					<button onClick={() => addTestCase()}>Add</button></h4>
					<p>{"Example Input of 2 arguments(1 array, and 1 int): \"[[1,2,3],1]\"" }</p>
					<p>{"Example Output for an int: \"1\"" }</p>
				</label><br/>
				{testCases.map((tc, i) => (
					<div key={i}>
						<label>
							{(i + 1) + ": "}
							<button onClick={() => removeTestCase(i)} >Remove</button>
						</label><br/>
						<label>
							{"Input: "}
							<input type="text" value={testCases[i].Input} onChange={(event) => {
								let newTCs = [...testCases];
								newTCs[i].Input = event.target.value;
								setTestCases(newTCs);
							}} />
						</label><br/>
						<label>
							{"Expected Output: "}
							<input type="text" value={testCases[i].ExpectedOutput} onChange={(event) => {
								let newTCs = [...testCases];
								newTCs[i].ExpectedOutput = event.target.value;
								setTestCases(newTCs);
							}} />
						</label><br/>
					</div>
				))}
		</div>
	)
}

function EditQuestion(props) {
	const [title, setTitle] = useState("");
	const [summary, setSummary] = useState("");
	const [difficulty, setDifficulty] = useState("");
	const [descriptions, setDescriptions] = useState([]);
	const [originalDescriptions, setOriginalDescriptions] = useState([]);
	const [answerFrameEntryPoint, setAnswerFrameEntryPoint] = useState("");
	const [answerFrame, setAnswerFrame] = useState("");
	const [solution, setSolution] = useState("");
	const [testCases, setTestCases] = useState([]);
	const [totalTestCases, setTotalTestCases] = useState(0);

	const { getTokenSilently } = useAuth0(); 

	useEffect(() => {
		setTitle(props.Question.Title);
		setSummary(props.Question.Summary);
		setDifficulty(props.Question.Difficulty.Id);
		setAnswerFrame(props.Question.AnswerFrame);
		setAnswerFrameEntryPoint(props.Question.AnswerFrameEntrypoint);
		var restClient = new RestClient();

		async function getSolution() {
			let token = await getTokenSilently();

			restClient.GetQuestionAdmin((question) => {
				setSolution(question.SolutionCode)
			}, props.Question.Uuid, token);	

			restClient.GetTestCases(((testCases) => {
					let frontEndTestCases = [];
					let max = 0;
					for(let i = 0; i < testCases.length; i++) {
						if(testCases[i].TestCaseNumber > max) {
							max = testCases[i].TestCaseNumber;
						}

						frontEndTestCases.push({
							TestCaseNumber: testCases[i].TestCaseNumber,
							Input: testCases[i].Input,
							ExpectedOutput: testCases[i].ExpectedOutput
						});
					}

					setTotalTestCases(max);
					setTestCases(frontEndTestCases);
			}), props.Question.Uuid, token);
		}

		getSolution();

		restClient.GetDescriptions(((descriptions) => {
			let frontEndDescs = [];
			for(let i = 0; i < descriptions.length; i++) {
				frontEndDescs.push({
					Uuid: descriptions[i].Uuid,
					Content: descriptions[i].Content,
					Type: descriptions[i].DescriptionType.Id,
					SortOrder: descriptions[i].SortOrder
				});
			}

			setOriginalDescriptions(frontEndDescs);
			setDescriptions(frontEndDescs);
		}), props.Question.Uuid, true);
	}, [props.Question, setTitle, setSummary, setDifficulty, setDescriptions, setOriginalDescriptions, setAnswerFrameEntryPoint, setAnswerFrame, setSolution, getTokenSilently, setTestCases, setTotalTestCases]);

	const editQuestion = (async () => {
		let token = await getTokenSilently();
		let restClient = new RestClient();
		restClient.EditQuestion(
			editQuestionCallback, 
			props.Question.Uuid, 
			title, 
			summary, 
			difficulty, 
			descriptions, 
			originalDescriptions, 
			token, 
			props.Difficulties, 
			props.DescriptionTypes, 
			answerFrameEntryPoint, 
			answerFrame, 
			solution,
			testCases,
			totalTestCases
		);
	});

	const editQuestionCallback = (() => {
		props.GetQuestions();
	});

	const deleteQuestion = (async () => {
		let token = await getTokenSilently();
		let restClient = new RestClient();
		restClient.DeleteQuestion(editQuestionCallback, props.Question.Uuid, token);
	});

	const removeDesc = ((index) => {
		let newDescs = [...descriptions];
		newDescs.splice(index, 1);
		setDescriptions(newDescs);
	});

	const addDesc = (() => {
		let newDescs = [...descriptions];
		newDescs.push({
			Content: "",
			Type: Object.keys(props.DescriptionTypes)[0],
			SortOrder: 1,
		});
		setDescriptions(newDescs);
	});

	const addTestCase = (() => {
		let newTestCases = [...testCases];
		newTestCases.push({
			"Input": "",
			"ExpectedOutput": "",
		});
		setTestCases(newTestCases);

	});

	const removeTestCase = ((index) => {
		let newTestCases = [...testCases];
		newTestCases.splice(index, 1);
		setTestCases(newTestCases);
	});

	return (
		<div key={props.Question.Title}>
			<br/><button onClick={() => editQuestion()} >Submit Edit Question</button><br/>
			<br/><button onClick={() => deleteQuestion()} >Delete Question</button><br/><br/>
			<label>
				{"Title: "}
				<input type="text" value={title} onChange={(event) => setTitle(event.target.value)} />
			</label><br/>
			<label>
				{"Summary: "}
				<input type="text" value={summary} onChange={(event) => setSummary(event.target.value)} />
			</label><br/>
			<label>
				{"Answer Frame: "}
				<AceEditor
			    mode="python"
			    theme="dreamweaver"
			    name="New-Answer-Frame"
			    height="100px"
			    width="100%"
			    fontSize="0.9rem"
			    value={answerFrame}
			    onChange={(text) => setAnswerFrame(text)}
			    showPrintMargin={false}
				  setOptions={{
					  tabSize: 4,
				  }}
			  />
			</label><br/>
			<label>
				{"Answer Frame Entrypoint: "}
				<input type="text" value={answerFrameEntryPoint} onChange={(event) => setAnswerFrameEntryPoint(event.target.value)} />
			</label><br/>
			<label>
				{"Solution: "}
				<AceEditor
			    mode="python"
			    theme="dreamweaver"
			    name="New-Answer-Frame"
			    height="100px"
			    width="100%"
			    fontSize="0.9rem"
			    value={solution}
			    onChange={(text) => setSolution(text)}
			    showPrintMargin={false}
				  setOptions={{
					  tabSize: 4,
				  }}
			  />
			</label><br/>
			<label>
				{"Difficulty: "}
				<select value={difficulty} onChange={(event) => setDifficulty(event.target.value)} >
					{Object.keys(props.Difficulties).map((diff) => (
						<option key={diff} value={diff}>{props.Difficulties[diff]}</option>
					))}
				</select>
			</label><br/>
			<label>
				<h4>{"Descriptions: "}
				<button onClick={() => addDesc()}>Add</button></h4>
			</label><br/>
			{descriptions.map((desc, i) => (
				<div key={i}>
					<label>
						{(i + 1) + ": "}
						<button onClick={() => removeDesc(i)} >Remove</button>
					</label><br/>
					<label>
						{"Content: "}
						<input type="text" value={descriptions[i].Content} onChange={(event) => {
							let newDescs = [...descriptions];
							newDescs[i].Content = event.target.value;
							setDescriptions(newDescs);
						}} />
					</label><br/>
					<label>
						{"Type: "}
						<select value={descriptions[i].Type} onChange={(event) => {
							let newDescs = [...descriptions];
							newDescs[i].Type = event.target.value;
							setDescriptions(newDescs);
						}}>
							{Object.keys(props.DescriptionTypes).map((descT) => (
								<option key={descT} value={descT}>{props.DescriptionTypes[descT]}</option>
							))}
						</select>
					</label><br/>
					<label>
						{"Sort Order: "}
						<input type="number" value={descriptions[i].SortOrder} onChange={(event) => {
							let newDescs = [...descriptions];
							newDescs[i].SortOrder = event.target.value;
							setDescriptions(newDescs);
						}} />
					</label><br/>
				</div>
			))}
			<label>
				<h4>{"Test Cases: "}
				<button onClick={() => addTestCase()}>Add</button></h4>
				<p>{"Example Input of 2 arguments(1 array, and 1 int): \"[[1,2,3],1]\"" }</p>
				<p>{"Example Output for an int: \"1\"" }</p>
			</label><br/>
			{testCases.map((tc, i) => (
				<div key={i}>
					<label>
						{(i + 1) + ": "}
						<button onClick={() => removeTestCase(i)} >Remove</button>
					</label><br/>
					<label>
						{"Input: "}
						<input type="text" value={testCases[i].Input} onChange={(event) => {
							let newTCs = [...testCases];
							newTCs[i].Input = event.target.value;
							setTestCases(newTCs);
						}} />
					</label><br/>
					<label>
						{"Expected Output: "}
						<input type="text" value={testCases[i].ExpectedOutput} onChange={(event) => {
							let newTCs = [...testCases];
							newTCs[i].ExpectedOutput = event.target.value;
							setTestCases(newTCs);
						}} />
					</label><br/>
				</div>
			))}
		</div>
	)
}

export default Admin;