import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router';
import RestClient from "../Utilities/RestClient.js"
import "../Css/Questions.css"
import Loading from "./Loading.js"

function Questions(props) {
	const [redirect, setRedirect] = useState(null);
	const [questions, setQuestions] = useState(null);

	useEffect(() => {
		var restClient = new RestClient();
		restClient.GetQuestions(SetQuestions);
	}, []);

	const SetQuestions = ((q) => {
		setQuestions(q);
	});

  const HandleProblemOnClick = ((questionUuid) => {
    setRedirect(questionUuid);
  });

  if(redirect != null) {
    return <Redirect push to={`${process.env.PUBLIC_URL}/questions/${redirect}`} />;
  }

	return (
		<div className="Questions-Page">
			<h1>Questions</h1>
      {questions == null ? (
        <Loading />
      )
			: ( questions.map( (question) =>
				<div className="QuestionBox" key={question.Uuid} onClick={() => HandleProblemOnClick(question.Uuid)}>
					<div className="QuestionBox-Title" >
						<h2>{question.Title}</h2>
						<p>{question.Summary}</p>
					</div>
					<div className="QuestionBox-Difficulty" >
						<h3>{question.Difficulty.Title}</h3>
					</div>
					<div className="QuestionBox-Difficulty" >
					</div>
				</div>
			))}
		</div>
	);
}

export default Questions;