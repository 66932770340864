import jwtDecode from "jwt-decode";

class DecodeJWT {
	Decode(token) {
		let decodedToken = jwtDecode(token);
		return decodedToken;
	}
	IsAdmin(token) {
		let decodedToken = this.Decode(token);
    let permissions = decodedToken.scope.split(" ");
   	return permissions.includes("waleetcode:admin");
	}
}

export default DecodeJWT;