import React from "react";
import loading from "../Assets/loading.svg"
import "../Css/Loading.css"

function Loading(props) {
	return (
		<div className="Loading">
			<center>
				<img src={loading} alt="Loading" />
				<h2>Loading</h2>
			</center>
		</div>
	);
}

export default Loading;