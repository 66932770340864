import React, { useState, useEffect } from "react";
import RestClient from "../Utilities/RestClient.js";
import SplitPane from "react-split-pane";
import "../Css/Split-Plane.css";
import "../Css/Problem.css";
import Loading from "./Loading.js"
import { useAuth0 } from "../Utilities/Auth0";
import AceEditor from 'react-ace';
import "brace/mode/python";
import "brace/mode/plain_text";
import "brace/theme/dreamweaver"
import loading from "../Assets/loading.svg"

function Problem(props) {
	const [description, setDescription] = useState(null);
	const [question, setQuestion] = useState(null);
	const [editorText, setEditorText] = useState("");

	const [testOpen, setTestOpen] = useState(false);
	const [solutionOpen, setSolutionOpen] = useState(false);
	const [submitOpen, setSubmitOpen] = useState(false);
	const [discussOpen, setDiscussOpen] = useState(false);

	const [testResult, setTestResult] = useState("");
	const [testCase, setTestCase] = useState("");
	const [loadingTest, setLoadingTest] = useState(false);

	const [submissionResult, setSubmissionResult] = useState(null);
	const [loadingSubmission, setLoadingSubmission] = useState(false);
	const [submissionResultError, setSubmissionResultError] = useState(false);

	const { getTokenSilently } = useAuth0();

	useEffect(() => {
		var restClient = new RestClient();
		restClient.GetDescriptions(SetDescription, props.match.params.QuestionUuid, true);
		restClient.GetQuestion(SetQuestion, props.match.params.QuestionUuid);
		restClient.GetDefaultTestCase(SetDefaultTestCase, props.match.params.QuestionUuid);
	}, [props.match.params.QuestionUuid]);

	const SetDescription = ((descriptions) => {
		setDescription(descriptions);
	});

	const SetQuestion = ((question) => {
		setQuestion(question);
		setEditorText(question.AnswerFrame)
	});

	const SetDefaultTestCase = ((testCase) => {
		setTestCase(testCase.Input.substring(1, testCase.Input.length - 1));
	});

	const DifficultyStyle = (() => {
		if(question.Difficulty.Id === 1) {
			return "Difficulty-Easy";
		} else if(question.Difficulty.Id === 2) {
			return "Difficulty-Medium";
		} else if(question.Difficulty.Id === 3) {
			return "Difficulty-Hard";
		}
	});

	const OnEditorChange = ((text) => {
		setEditorText(text);
	})

	const toggleTest = (() => {
		setTestOpen(!testOpen);
	});

	const pressTest = (async(e) => {
		e.stopPropagation();
		if(testCase === ""){
			alert("Invalid Test Cases");
			return;
		}

		setTestOpen(true);
		if(loadingTest) {
			return;
		}

		setLoadingTest(true);
		let token = await getTokenSilently();
		var restClient = new RestClient();
		restClient.SubmitTestRun(pressTestCallBack, pressTestCallBackTooManySubmissions, props.match.params.QuestionUuid, editorText, token, testCase);
	});

	const pressTestCallBack = ((response) => {
		setTestResult(response);
		setLoadingTest(false);
	});

	const pressTestCallBackTooManySubmissions = ((response) => {
		let res = {};
		res.Error = response;
		console.log(res);
		setTestResult(res);
		setLoadingTest(false);
	});

	const toggleSolution = (() => {
		setSolutionOpen(!solutionOpen);
	});

	const toggleSubmit = (() => {
		setSubmitOpen(!submitOpen);
	});

	const pressSubmit = (async(e) => {
		e.stopPropagation();
		setSubmitOpen(true);
		if(loadingSubmission) {
			return;
		}

		setSubmissionResultError(false);
		setLoadingSubmission(true);
		let token = await getTokenSilently();
		var restClient = new RestClient();
		restClient.SubmitAnswer(pressSubmitCallback, pressSubmitCallbackTooManySubmissions, props.match.params.QuestionUuid, editorText, token) 
	});

	const pressSubmitCallback = ((response) => {
		setSubmissionResult(response);
		setLoadingSubmission(false);
	});

	const pressSubmitCallbackTooManySubmissions = ((response) => {
		let res = {};
		res.Error = response;
		setSubmissionResult(res);
		setSubmissionResultError(true);
		setLoadingSubmission(false);
	});

	const toggleDiscussion = (() => {
		setDiscussOpen(!discussOpen);
	});

	return (
		<div>
      {description == null || question == null ? (
        <Loading />
      )
			: ( 
				<div className="Problem-Outter">
      		<SplitPane split="vertical" defaultSize="50%" minSize={50} className="SplitPlane">
  					<div className="Problem-Left">
	  					<div className="Problem-Left-Title">
	  						<h1 className="Problem-Title">{question.Title}</h1>
	  						<p className="Problem-Summary">{question.Summary}</p>
	  						<p className={DifficultyStyle()}>{question.Difficulty.Title}</p>
	  						{description.map((desc) => (
	  							<div>
	  								{desc.Content}
	  							</div>
	  						))}
  						</div>
  						<div className="Problem-Left-Window">
  							<div onClick={() => toggleTest()} className="Problem-Left-Window-Header Problem-Left-Window-Header-With-Button">
  								<h2>Test Run</h2>
  								<div onClick={(e) => pressTest(e)}>
  									<p>Run Code</p>
  								</div>
  							</div>
								<div className={testOpen ? "Problem-Left-Window-Content" : "Problem-Left-Window-Content-Hidden"}>
									{testOpen && 
										<div className="Problem-Left-Test">
		  								<h3>Test Cases</h3>
												<AceEditor
													className="Problem-Left-Test-Ace-Editor"
											    mode="plain_text"
											    theme="dreamweaver"
											    name="Code-Test-Case"
											    height="100px"
											    width="100%"
											    fontSize="0.9rem"
											    value={testCase}
											    onChange={(text) => setTestCase(text)}
											    showPrintMargin={false}
												  setOptions={{
													  enableBasicAutocompletion: false,
													  enableLiveAutocompletion: false,
													  showLineNumbers: false,
													  tabSize: 4,
													  highlightActiveLine: false,
													  showGutter: false,
													  cursorStart: 1000000000
												  }}
											  />
											<h3>Result</h3>
											{!loadingTest &&
												<div>
													{testResult.Error != "" ? (
														<div>
													  	<h4>Error:</h4>
															<AceEditor
																className="Problem-Left-Test-Ace-Editor"
														    mode="python"
														    theme="dreamweaver"
														    name="Code-Test-Result"
														    height="100px"
														    width="100%"
														    fontSize="0.9rem"
														    value={testResult.Error != null ? testResult.Error : ""}
														    showPrintMargin={false}
															  setOptions={{
																  enableBasicAutocompletion: false,
																  enableLiveAutocompletion: false,
																  showLineNumbers: false,
																  tabSize: 4,
																  readOnly: true,
																  highlightActiveLine: false,
																  showGutter: false,
																  cursorStart: 1000000000
															  }}
														  />
												  	</div>	
												  ) : (
														<div>
															<h4>Output:</h4>
															<p>Runtime: {parseFloat(testResult.Time).toFixed(2)} ms &nbsp;Memory: {parseFloat(testResult.Memory).toFixed(2)} mb</p>
															<AceEditor
																className="Problem-Left-Test-Ace-Editor"
														    mode="python"
														    theme="dreamweaver"
														    name="Code-Test-Result"
														    height="100px"
														    width="100%"
														    fontSize="0.9rem"
														    value={testResult.TestRunResults != null ? testResult.TestRunResults.join("\n") : ""}
														    showPrintMargin={false}
															  setOptions={{
																  enableBasicAutocompletion: false,
																  enableLiveAutocompletion: false,
																  showLineNumbers: false,
																  tabSize: 4,
																  readOnly: true,
																  highlightActiveLine: false,
																  showGutter: false,
																  cursorStart: 1000000000
															  }}
														  />
															<h4>Expected:</h4>
															<AceEditor
																className="Problem-Left-Test-Ace-Editor"
														    mode="python"
														    theme="dreamweaver"
														    name="Code-Test-Result"
														    height="100px"
														    width="100%"
														    fontSize="0.9rem"
														    value={testResult.SolutionResults != null ? testResult.SolutionResults.join("\n") : ""}
														    showPrintMargin={false}
															  setOptions={{
																  enableBasicAutocompletion: false,
																  enableLiveAutocompletion: false,
																  showLineNumbers: false,
																  tabSize: 4,
																  readOnly: true,
																  highlightActiveLine: false,
																  showGutter: false,
																  cursorStart: 1000000000
															  }}
														  />
												  	</div>	
												  )}
											  </div>
											}
											{loadingTest &&
												<div className="Problem-Loading">
													<img src={loading} alt="Loading" />
													<p>Running Test Cases...</p>
												</div>
											}
										</div>
									}
								</div>
  						</div>
  						<div onClick={() => toggleSolution()} className="Problem-Left-Window">
  							<div className="Problem-Left-Window-Header">
  								<h2>Solution</h2>
  							</div>
								<div className={solutionOpen ? "Problem-Left-Window-Content" : "Problem-Left-Window-Content-Hidden"}>
									{solutionOpen && 
										<div>
										</div>
									}
								</div>
  						</div>
  						<div className="Problem-Left-Window">
  							<div onClick={() => toggleSubmit()} className="Problem-Left-Window-Header Problem-Left-Window-Header-With-Button">
  								<h2>Submit</h2>
  								<div onClick={(e) => pressSubmit(e)}>
  									<p>Submit</p>
  								</div>
  							</div>
								<div className={submitOpen ? "Problem-Left-Window-Content" : "Problem-Left-Window-Content-Hidden"}>
									{submitOpen && 
										<div>
											{!loadingSubmission && submissionResult !== null &&
												<div>
													{!submissionResultError && submissionResult.Accepted ? (
														<div>
													  	<h3>Accepted</h3>
													  	<p>Runtime: {submissionResult.RunTime.toFixed(2)} ms</p>
													  	<p>Memory: {submissionResult.MemoryUsage.toFixed(2)} mb</p>
													  	<p>Test Cases Passed: {submissionResult.TestCasesPassed}/{submissionResult.TestCasesTotal}</p>
												  	</div>	
												  ) : !submissionResultError && !submissionResult.Accepted ? (
														<div>
															<h3>Rejected</h3>
													  	<p>Test Cases Passed: {submissionResult.TestCasesPassed}/{submissionResult.TestCasesTotal}</p>
															<h4>TestCase:</h4>
															<AceEditor
																className="Problem-Left-Test-Ace-Editor"
														    mode="python"
														    theme="dreamweaver"
														    name="Code-Test-Result"
														    height="100px"
														    width="100%"
														    fontSize="0.9rem"
														    value={submissionResult.FailedTestCase != "" && submissionResult.FailedTestCase != null ? submissionResult.FailedTestCase.substring(1, submissionResult.FailedTestCase.length - 1) : ""}
														    showPrintMargin={false}
															  setOptions={{
																  enableBasicAutocompletion: false,
																  enableLiveAutocompletion: false,
																  showLineNumbers: false,
																  tabSize: 4,
																  readOnly: true,
																  highlightActiveLine: false,
																  showGutter: false,
																  cursorStart: 1000000000
															  }}
														  />
															<h4>Output:</h4>
															<AceEditor
																className="Problem-Left-Test-Ace-Editor"
														    mode="python"
														    theme="dreamweaver"
														    name="Code-Test-Result"
														    height="100px"
														    width="100%"
														    fontSize="0.9rem"
														    value={submissionResult.FailedTestCaseResult}
														    showPrintMargin={false}
															  setOptions={{
																  enableBasicAutocompletion: false,
																  enableLiveAutocompletion: false,
																  showLineNumbers: false,
																  tabSize: 4,
																  readOnly: true,
																  highlightActiveLine: false,
																  showGutter: false,
																  cursorStart: 1000000000
															  }}
														  />
															<h4>Expected:</h4>
															<AceEditor
																className="Problem-Left-Test-Ace-Editor"
														    mode="python"
														    theme="dreamweaver"
														    name="Code-Test-Result"
														    height="100px"
														    width="100%"
														    fontSize="0.9rem"
														    value={submissionResult.FailedTestCaseExpected}
														    showPrintMargin={false}
															  setOptions={{
																  enableBasicAutocompletion: false,
																  enableLiveAutocompletion: false,
																  showLineNumbers: false,
																  tabSize: 4,
																  readOnly: true,
																  highlightActiveLine: false,
																  showGutter: false,
																  cursorStart: 1000000000
															  }}
														  />
												  	</div>	
												  ) : (
														<div>
															<h3>Error</h3>
													  	<p>{submissionResult.Error}</p>
														</div>
													)}
											  </div>
											}
											{loadingSubmission &&
												<div className="Problem-Loading">
													<img src={loading} alt="Loading" />
													<p>Running Submission...</p>
												</div>
											}
										</div>
									}
								</div>
  						</div>  						
  						<div onClick={() => toggleDiscussion()} className="Problem-Left-Window">
  							<div className="Problem-Left-Window-Header">
  								<h2>Discuss</h2>
  							</div>
								<div className={discussOpen ? "Problem-Left-Window-Content" : "Problem-Left-Window-Content-Hidden"}>
									{discussOpen && 
										<div>
											
										</div>
									}
								</div>
  						</div>
  					</div>
  					<div className="Problem-Right">
  						<div className="Code-Input">
	  						<AceEditor
							    mode="python"
							    theme="dreamweaver"
							    name="Code-Input-TextArea"
							    height="100%"
							    width="100%"
							    fontSize="0.9rem"
							    value={editorText}
							    onChange={OnEditorChange}
							    showPrintMargin={false}
								  setOptions={{
									  enableBasicAutocompletion: false,
									  enableLiveAutocompletion: false,
									  showLineNumbers: true,
									  tabSize: 4,
								  }}
							  />
  						</div>
  					</div>
      		</SplitPane>
    		</div>
			)}
		</div>
	);
}

export default Problem;